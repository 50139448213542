.step-progress {
  width: 100%;
  padding: 20px 0;
  margin-bottom: 30px;
}

.step-progress-bar {
  display: flex;
  justify-content: space-between;
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.step-progress-item {
  flex: 1;
  text-align: center;
  position: relative;
  color: #6c757d;
}

.step-progress-item .step-icon {
  display: inline-block;
  width: 40px;
  height: 40px;
  line-height: 40px;
  border-radius: 50%;
  background: #e9ecef;
  color: #fff;
  font-size: 20px;
  margin-bottom: 5px;
}

.step-progress-item .step-label {
  font-size: 14px;
}

.step-progress-item.completed .step-icon {
  background: #28a745;
}

.step-progress-item.completed .step-label {
  color: #28a745;
}

.step-progress-item.current .step-icon {
  background: #28a745;
}

.step-progress-item.current .step-label {
  color: #28a745;
}

.step-progress-item:not(:last-child)::after {
  content: '';
  position: absolute;
  top: 50%;
  right: -50%;
  width: 100%;
  height: 2px;
  background: #e9ecef;
  transform: translateY(-50%);
  z-index: -1;
}

.step-progress-item.completed:not(:last-child)::after {
  background: #28a745;
}
