.card {
  margin-top: 20px;
}

.card-body {
  padding: 20px;
}

.valor {
  font-weight: bold;
}
