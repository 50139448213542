/* Estilos para o Simulador */

.container {
  padding: 40px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 40px;
}

.main-content {
  display: flex;
  width: 100%;
  justify-content: space-between;
}

.info-section {
  flex: 1;
  padding: 20px;
}

.form-section {
  flex: 1;
  padding: 20px;
  box-sizing: border-box;
}

.form-container {
  width: 100%;
}

.form-label {
  color: #0C0B0F;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.form-control {
  width: 100%;
  padding: 16px;
  border-radius: 5px;
  border: 1px solid #267E3E;
  background: #Fafafa;
  font-family: Inter, sans-serif;
  font-size: 16px;
  color: #0C0B0F;
}

.form-control::placeholder {
  color: #A6A6A6;
}

.form-control:focus {
  border-color: #267E3E;
  box-shadow: 0 0 0 3px rgba(38, 126, 62, 0.2);
}

.invalid-feedback {
  color: #d9534f;
  font-size: 12px;
}

.btn-continuar {
  padding: 16px;
  font-family: Inter, sans-serif;
  font-size: 16px;
  font-weight: 600;
  border-radius: 5px;
  border: none;
  background-color: #267E3E;
  color: #FFF;
  cursor: pointer;
  width: 100%;
}

.btn-continuar:hover {
  background-color: #1f6732;
}

.btn-continuar:disabled {
  background-color: #A6A6A6;
  cursor: not-allowed;
}

.input-group-text {
  border: none;
  background-color: #fafafa;
}

.title-desc {
  margin-bottom: 20px;
}

.paragrah-desc {
  margin-bottom: 20px;
}

.icon-form {
  color: #6D6D6D;
}

.btn-link {
  background: none;
  border: none;
  color: #267E3E;
  text-decoration: none;
  cursor: pointer;
  font-size: 16px;
  margin-bottom: 10px;
}

.btn-link:hover {
  color: #0056b3;
  text-decoration: none;
}

.img-icon {
  margin-bottom: 20px;
}

.logo-arbore {
  height: 40px;
}

.logo-caixa-minha {
  max-width: 90%;
  margin-bottom: 20px;
}

.casa-propria {
  font-weight: bold;
  color: #267E3E;
  margin-bottom: 20px;
}

.simular-text {
  margin-bottom: 20px;
}

.simular-text span {
  color: #267E3E;
}

.texto-auxiliar {
  font-size: 12px;
  color: #A6A6A6;
}

.banco-container {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.banco-label img {
  max-width: 80px;
  height: auto;
  transition: transform 0.2s, box-shadow 0.2s;
}

.banco-label:hover img,
.banco-label img:active {
  transform: scale(1.1);
  box-shadow: 0 0 10px rgba(38, 126, 62, 0.5);
}

.resultado-container {
  margin-top: 20px;
  width: 100%;
}

.resultado-item {
  background: #F5F5F5;
  padding: 10px;
  border-radius: 5px;
  margin-bottom: 10px;
}

.resultado-item p {
  margin: 0;
}

.resultado-item .valor {
  font-size: 24px;
  color: #267E3E;
  font-weight: bold;
}

/* Responsividade adicional */
@media (max-width: 768px) {
  .info-section, .form-section {
    flex: 1 1 100%;
    max-width: 100%;
  }

  .banco-label img {
    max-width: 60px;
  }

  .form-container .form-control, .btn-continuar {
    font-size: 14px;
    padding: 12px;
  }

  .form-container .form-label {
    font-size: 14px;
  }
}

/* Customizações para os botões FGTS */
.btn-outline-success {
  color: #1f6732;
  border-color: #1f6732;
}

.btn-outline-success:hover {
  background-color: #1f6732;
  color: #fff;
}

.btn-success {
  background-color: #1f6732;
  border-color: #1f6732;
}

.btn-success:hover {
  background-color: #155a25;
  border-color: #155a25;
}

/* Customizações para o input range */
input[type="range"] {
  -webkit-appearance: none;
  width: 100%;
  height: 8px;
  background: #d3d3d3;
  outline: none;
  opacity: 0.7;
  transition: opacity .2s;
  border-radius: 20%;
}

input[type="range"]:hover {
  opacity: 1;
}

input[type="range"]::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  background: #1f6732;
  cursor: pointer;
  border-radius: 50%;
}

input[type="range"]::-moz-range-thumb {
  background: #1f6732;
  cursor: pointer;
  border-radius: 50%;
}

.entrada-label{
  color: #6D6D6D;
  font-size: 12px;
}
